<template>
  <div>
    <div>
      <van-nav-bar
        title="黄金数据"
        left-arrow
        fixed="true"
        @click-left="Close"
      />
    </div>

    <!--<div class="main">

  <van-tabs active="a">
   <van-tab title="上海黄金交易所" name="a">
     <div class="bd">
       <div>
          <span class="info6">今日实时黄金价格</span>
          <span class="word8">数据参考上海黄金交易所</span>
        </div>
     </div>

   </van-tab>
   <van-tab title="上海期货交易所" name="b"  >    
     <div class="bd"><div>
          <span class="info6">今日实时黄金价格</span>
          <span class="word8">数据参考上海期货交易所</span>
        </div></div></van-tab>
   <van-tab title="纸黄金" name="c" >    
     <div class="bd"><div>
          <span class="info6">今日实时黄金价格</span>
          <span class="word8">数据参考纸黄金交易所</span>
        </div></div></van-tab>
</van-tabs>
  </div>-->
    <!--数据显示-->
    <div class="main">
      <div class="bd">
        <div>
          <span class="info6">今日实时黄金价格</span>
          <span class="word8">数据详情如下</span>
        </div>
      </div>
    </div>
    <div class="main2">
      <div><span class="word7">品种行情</span></div>
      <div class="word9">
        <van-col span="6">产品</van-col> <van-col span="6">最新价</van-col>
        <van-col span="6">开盘价</van-col> <van-col span="6">涨跌幅</van-col>
      </div>
      <div class="main3">
        <div
          v-for="(item, index) in golddata"
          :key="index"
          class="div1"
          @click="$router.push({ name: 'Golddatadetail', query: { index } })"
        >
          <van-col span="6" class="txt1">{{ item.variety }}</van-col>
          <van-col span="6" class="txt2">{{ item.latestpri }}</van-col>
          <van-col span="6" class="txt2">{{ item.openpri }}</van-col>
          <van-col span="6" class="txt3">{{ item.limit }}</van-col>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGoldObj } from "@/api/weatherforecast/weatherforecast";
//import bridge from '@/api/bridgeJs/bridge.js'
export default {
  data() {
    return {
      golddata: [],
      right: [],
    };
  },
  created() {
    this.getData();
    //this.timer = setInterval(() => {
    //   setTimeout(this.getData(), 0)
    //}, 1000)
  },
  methods: {
    getData() {
      let params = {};
      getGoldObj(params)
        .then((data) => {
          if (data.data.code === 0) {
            this.golddata = data.data.data.result[0];
          }
          //alert(JSON.stringify(this.golddata))
          //console.log(this.golddata)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 返回原生
    Close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    testDemo() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        //jsObj.webClose(); // 调用原生app的函数
      }
      if (isIOS) {
        window.webkit.messageHandlers.webClose.postMessage();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bd {
  height: 200px;
  background: url(/img/gold.png) 100% repeat;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  left: 0;
}
.main {
  position: fixed;
  margin-top: 50px;
  width: 100%;
}
.info6 {
  margin-top: 40px;
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 26px;
  font-family: PingFangSC-Medium;
  line-height: 37px;
  text-align: center;
  align-self: center;
}
.word8 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
}
.main2 {
  height: calc(100% - 210px);
  overflow: scroll;
  position: fixed;
  margin-top: 200px;
  margin-left: 3%;
  margin-right: 3%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  width: 94%;
}
.div1 {
  margin-top: 20px;
  padding-top: 20px;
}
.txt1 {
  color: rgba(204, 146, 39, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
}
.txt2 {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
}

.txt3 {
  overflow-wrap: break-word;
  color: rgba(16, 138, 0, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
}
.word7 {
  margin-left: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.word9 {
  margin-left: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}
</style>
